
import { Options, Vue } from 'vue-class-component'
import AddClassAndLabel from '../components/AddClassAndLabel.vue'
import { Http, LogConsole, WebApi } from '@/common/HttpCommon'
import { Watch } from 'vue-property-decorator'
import $ from 'jquery'
import ChooseLabelDialog from '@/components/ChooseLabelDialog.vue'

@Options({
  components: { ChooseLabelDialog, AddClassAndLabel }
})

export default class AddCodeBlock extends Vue {
  title = ''
  description = ''
  code = ''
  classId = ''
  classify = []
  labelIdStr = ''
  labelNameStr = 'choose label'

  modifyCodeBlockObj: any
  isEditCodeBlock = false

  $refs!: {
    addClassAndLabel: HTMLFormElement,
    chooseLabel: HTMLFormElement
  }

  mounted (): void {
    this.selectClassList()
    // 获取修改代码块时的传值
    const codeBlockContent = this.$route.params.codeBlockItem
    if (codeBlockContent !== undefined) {
      this.modifyCodeBlockObj = JSON.parse(codeBlockContent as string)
      this.title = this.modifyCodeBlockObj.title
      this.description = this.modifyCodeBlockObj.description
      this.code = this.modifyCodeBlockObj.content
      this.isEditCodeBlock = true
    }
  }

  private addCaL (type: number): void {
    this.$refs.addClassAndLabel.openAddClassLabel(type)
  }

  /**
   * 打开或关闭多选标签
   * */
  private openLabelCheckBox (): void {
    this.$refs.chooseLabel.openDialog(this.classId)
  }

  /**
   * 提供给ChooseLabelDialog 调用 赋值选择的标签
   * */
  childMethodChooseLabelId (ids: any, names: any): void {
    this.labelIdStr = ids
    this.labelNameStr = names
  }

  /**
   * 查询分类列表
   * @private
   */
  private selectClassList (): void {
    const params = new FormData()
    params.append('page', '1')
    params.append('pageSize', '9999')
    Http.requestPost(WebApi.QUERY_CLASSIFY_LIST, params, success => {
      LogConsole.showConsoleInfo(success)
      this.classify = success.data
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }

  /**
   * 添加代码块
   * @private
   */
  private addCodeBlock (): void {
    // 如果是修改代码块 则返回
    if (this.isEditCodeBlock) {
      this.modifyCodeBlock()
      return
    }

    if (this.title.trim() === '') {
      (this as any).$parent.$parent.showRemindDialog('Please enter a title')
    } else if (this.classId === '') {
      (this as any).$parent.$parent.showRemindDialog('Please select a type')
    } else if (this.labelIdStr.length === 0) {
      (this as any).$parent.$parent.showRemindDialog('Please select a label')
    } else if (this.description === '') {
      (this as any).$parent.$parent.showRemindDialog('Please enter a description')
    } else if (this.code.trim() === '') {
      (this as any).$parent.$parent.showRemindDialog('Please enter content')
    } else {
      const params = new FormData()
      params.append('title', this.title)
      params.append('labelIds', this.labelIdStr)
      params.append('description', this.description)
      params.append('content', this.code)
      Http.requestPost(WebApi.ADD_CODE_BLOCK, params, success => {
        LogConsole.showConsoleInfo(success)
        if (success.code === 200) {
          this.$router.push({ path: '/' })
        } else {
          (this as any).$parent.$parent.showRemindDialog(success.msg)
        }
      }, error => {
        LogConsole.showConsoleError(error)
      })
    }
  }

  /**
   * 修改
   * @private
   */
  private modifyCodeBlock (): void {
    if (this.title.trim() === '') {
      (this as any).$parent.$parent.showRemindDialog('Please enter a title')
    } else if (this.description === '') {
      (this as any).$parent.$parent.showRemindDialog('Please enter a description')
    } else if (this.code.trim() === '') {
      (this as any).$parent.$parent.showRemindDialog('The content can not be blank')
    } else {
      const params = new FormData()
      params.append('id', this.modifyCodeBlockObj.id)
      params.append('title', this.title)
      params.append('description', this.description)
      params.append('content', this.code)
      Http.requestPost(WebApi.MODIFY_CODE_BLOCK, params, success => {
        LogConsole.showConsoleInfo(success)
        if (success.code === 200) {
          this.$router.push({ path: '/' })
        } else {
          (this as any).$parent.$parent.showRemindDialog(success.msg)
        }
      }, error => {
        LogConsole.showConsoleError(error)
      })
    }
  }
}
