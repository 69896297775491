
import { Vue } from 'vue-class-component'
import { LogConsole } from '@/common/HttpCommon'

export default class Android extends Vue {
  private goLogin (): void {
    (window as any).android.goLogin()
  }

  mounted (): void {
    this.initCanvas()
    window.onresize = () => {
      this.initCanvas()
    }
  }

  initCanvas (): void {
    const c = document.getElementById('canvas') as HTMLCanvasElement
    const ctx = c.getContext('2d') as CanvasRenderingContext2D
    this.resizeCanvas(c)
    const w = c.width
    const h = c.height
    ctx.fillStyle = '#FFFFFF'
    ctx.fillRect(0, 0, w, h)
    ctx.fillStyle = '#FF0000'
    ctx.lineWidth = 1
    var cp1x = 300
    var cp1y = 600
    var cp2x = 300
    var cp2y = 100

    ctx.clearRect(0, 0, w, h)
    ctx.beginPath()
    ctx.moveTo(100, 300)
    ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, 600, 300)
    ctx.stroke()
  }

  resizeCanvas (canvas: HTMLCanvasElement) {
    LogConsole.showConsoleInfo(window.innerWidth)
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
  }
}
