<template>
  <div>
    <br/>
    <h3>Other Settings</h3>
    <br/>
    <div>
      Baidu Search Filter CSDN Result：
      <label>
        <input type="radio" name="mt" :checked="isOpenFilterSCDN === '1'" @change.stop="onSwitchClick('1')">&nbsp;YES
      </label>&nbsp;&nbsp;
      <label>
        <input type="radio" name="mt" :checked="isOpenFilterSCDN !== '1'" @change.stop="onSwitchClick('0')">&nbsp;NO
      </label>
    </div>
  </div>
</template>

<script>
import { Vue } from 'vue-class-component'

export default class OtherSettings extends Vue {
  isOpenFilterSCDN = '1'
  mounted () {
    let i = window.localStorage.getItem('isOpenFilterCSDN')
    if (i == null) {
      i = '1'
    }
    this.isOpenFilterSCDN = i
  }

  onSwitchClick (i) {
    this.isOpenFilterSCDN = i
    window.localStorage.setItem('isOpenFilterCSDN', i)
  }
}
</script>

<style scoped>

</style>
