
import { Vue } from 'vue-class-component'
import { Http, LogConsole, WebApi } from '@/common/HttpCommon'

export default class DeletedCodeBlock extends Vue {
  delCbs = []
  cbObj:any = Object
  isMenuShow = false
  menuOffsetTop = 0
  menuOffsetLeft = 0
  mounted () {
    this.queryMineDelCb()
  }

  private openMenu (cb: any, event: any): void {
    this.cbObj = cb
    this.isMenuShow = true
    this.menuOffsetTop = event.pageY
    this.menuOffsetLeft = event.pageX
  }

  /**
   * 彻底删除CB
   * */
  private removeCompletely (): void {
    this.isMenuShow = false
    const params = new FormData()
    params.append('id', this.cbObj.id)
    Http.requestPost(WebApi.REMOVE_COMPLETELY_CODE_BLOCK, params, success => {
      LogConsole.showConsoleInfo(success)
      if (success.code === 200) {
        this.queryMineDelCb()
      } else {
        alert(success.msg)
      }
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }

  /**
   * 查询已删除的CB
   * @private
   */
  private queryMineDelCb (): void {
    const params = new FormData()
    Http.requestPost(WebApi.QUERY_MINE_DELETE_CB, params, success => {
      LogConsole.showConsoleInfo(success)
      this.delCbs = success.data
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }
}
