
import { Options, Vue } from 'vue-class-component'
import AddClassAndLabel from '../components/AddClassAndLabel.vue'
import { Http, LogConsole, WebApi } from '@/common/HttpCommon'
import { Watch } from 'vue-property-decorator'
import $ from 'jquery'

@Options({
  components: { AddClassAndLabel }
})

export default class AddLinks extends Vue {
  title = ''
  code = ''
  id = ''

  mounted () {
    this.title = this.$route.params.title as string
    this.code = this.$route.params.code as string
    this.id = this.$route.params.id as string
  }

  /**
   * 修改Link
   * */
  private modifyLinks () {
    if (this.title.trim() === '') {
      (this as any).$parent.$parent.showRemindDialog('Please enter a title')
    } else if (this.code.trim() === '') {
      (this as any).$parent.$parent.showRemindDialog('Please enter content')
    } else {
      const params = new FormData()
      params.append('id', this.id)
      params.append('name', this.title)
      params.append('link', this.code)
      Http.requestPost(WebApi.MODIFY_LINK, params, success => {
        LogConsole.showConsoleInfo(success)
        if (success.code === 200) {
          this.$router.push({ path: '/linkList' })
        } else {
          (this as any).$parent.$parent.showRemindDialog(success.msg)
        }
      }, error => {
        LogConsole.showConsoleError(error)
      })
    }
  }

  /**
   * 添加代码块
   * @private
   */
  private addCodeBlock (): void {
    if (this.title.trim() === '') {
      (this as any).$parent.$parent.showRemindDialog('Please enter a title')
    } else if (this.code.trim() === '') {
      (this as any).$parent.$parent.showRemindDialog('Please enter content')
    } else {
      const params = new FormData()
      params.append('name', this.title)
      params.append('link', this.code)
      Http.requestPost(WebApi.ADD_LINK, params, success => {
        LogConsole.showConsoleInfo(success)
        if (success.code === 200) {
          this.$router.push({ path: '/linkList' })
        } else {
          (this as any).$parent.$parent.showRemindDialog(success.msg)
        }
      }, error => {
        LogConsole.showConsoleError(error)
      })
    }
  }
}
