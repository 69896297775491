
import { Vue } from 'vue-class-component'
import { Http, LogConsole, RandomColor, WebApi } from '../common/HttpCommon'
import $ from 'jquery'

export default class LinkList extends Vue {
  previewUrl = ''
  links = []

  private getRandomColorClass (): { color: string; display: string; 'border-color': string } {
    const colorS = RandomColor.getRandomColor()
    return {
      color: colorS,
      'border-color': colorS,
      display: 'inline-block'
    }
  }

  mounted (): void {
    this.queryLinks()
  }

  private showPreview (url: string): void {
    this.previewUrl = url
    const $preview = $('#preview')
    $preview.show(300)
  }

  private hidePreview (): void {
    this.previewUrl = ''
    const $preview = $('#preview')
    $preview.hide()
  }

  private setupPreviewPos (event: any): void {
    const $preview = $('#preview')
    $preview.offset({ top: event.pageY + 10, left: event.pageX + 10 })
  }

  /**
   * 查询列表
   * @private
   */
  private queryLinks (): void {
    const params = new FormData()
    Http.requestPost(WebApi.QUERY_LINKS, params, success => {
      LogConsole.showConsoleInfo(success)
      this.links = success.data
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }
}
