
import Loading from '@/components/Loading.vue'
import { Options, Vue } from 'vue-class-component'
import $ from 'jquery'
import { LogConsole, Http, WebApi } from '@/common/HttpCommon'

@Options({
  components: { Loading }
})
export default class AddClassAndLabel extends Vue {
  content = ''
  errorMsg = ''
  addType = 0
  classId = '1'
  classify = []
  isLoading = false

  clickBlank (): void {
    // LogConsole.showConsoleInfo('clickBlank')
  }

  mounted (): void {
    const $this = $('#add-container-parent')
    $this.animate({ opacity: 0 }, 500)
    $this.hide()
  }

  closeClick (): void {
    const $this = $('#add-container-parent')
    $this.animate({ opacity: 0 }, 500)
    const interval = setInterval(() => {
      $this.hide()
      clearInterval(interval)
    }, 500)
  }

  openAddClassLabel (type: number): void {
    this.addType = type
    this.content = ''
    this.errorMsg = ''
    this.isLoading = false
    const $this = $('#add-container-parent')
    $this.show()
    $this.animate({ opacity: 1 }, 500)
    this.selectClassList()
  }

  /**
   * 查询分类列表
   * @private
   */
  private selectClassList (): void {
    const params = new FormData()
    params.append('page', '1')
    params.append('pageSize', '9999')
    Http.requestPost(WebApi.QUERY_CLASSIFY_LIST, params, success => {
      LogConsole.showConsoleInfo(success)
      this.classify = success.data
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }

  /**
   * addClassOrLabel
   */
  addClassOrLabel (): void {
    if (this.isLoading) {
      return
    }
    if (this.addType === 1) { // 添加分类
      if (this.content === '') {
        this.errorMsg = '请输入内容！'
      } else {
        const params = new FormData()
        this.isLoading = true
        params.append('name', this.content)
        Http.requestPost(WebApi.ADD_CLASSIFY, params, success => {
          this.isLoading = false
          LogConsole.showConsoleInfo(success)
          this.errorMsg = success.msg
          if (success.code === 200) {
            (this as any).$parent.selectClassList()
            this.closeClick()
          }
        }, error => {
          this.isLoading = false
          LogConsole.showConsoleError(error)
          this.errorMsg = error.message
        })
      }
    } else if (this.addType === 2) { // 添加标签
      // ADD_LABEL
      LogConsole.showConsoleInfo(this.classId)
      if (this.classId === '') {
        this.errorMsg = '请选择分类！'
      } else if (this.content === '') {
        this.errorMsg = '请输入内容！'
      } else {
        const params = new FormData()
        this.isLoading = true
        params.append('classId', this.classId)
        params.append('labelName', this.content)
        Http.requestPost(WebApi.ADD_LABEL, params, success => {
          this.isLoading = false
          LogConsole.showConsoleInfo(success)
          this.errorMsg = success.msg
          if (success.code === 200) {
            // (this as any).$parent.selectClassList()
            this.closeClick()
          }
        }, error => {
          this.isLoading = false
          LogConsole.showConsoleError(error)
          this.errorMsg = error.message
        })
      }
    }
  }
}

