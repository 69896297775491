
import { Vue } from 'vue-class-component'
import { Http, LogConsole, WebApi } from '@/common/HttpCommon'
import $ from 'jquery'

export default class LinkManager extends Vue {
  links = []
  openMenuIndex = -1
  mounted () {
    this.queryLinksm()
  }

  private openMenu (index: number) {
    this.openMenuIndex = index
  }

  private modifyLink (item: any) {
    this.$router.push({
      name: 'AddLinks',
      params: {
        title: item.name,
        code: item.link,
        id: item.id
      }
    })
  }

  /**
   * 删除一条Link
   * @private
   */
  private deleteLink (id: any): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _that = this
    const params = new FormData()
    params.append('id', id)
    Http.requestPost(WebApi.DELETE_LINK, params, success => {
      LogConsole.showConsoleInfo(success)
      if (success.code === 200) {
        window.location.reload()
      } else {
        // eslint-disable-next-line no-unused-expressions
        (_that as any).$parent.$parent.$parent.$parent.showRemindDialog(success.msg)
      }
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }

  /**
   * 查询列表
   * @private
   */
  private queryLinksm (): void {
    const params = new FormData()
    Http.requestPost(WebApi.QUERY_LINKS, params, success => {
      LogConsole.showConsoleInfo(success)
      this.links = success.data
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }
}
