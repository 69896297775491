<template>
  <div>
    <br/>
    <h3>User list</h3>
    <br/>
    <p style="color: red" v-if="errMsg !== ''">{{errMsg}}</p>
    <div>
      <template v-for="(item, index) in userList" :key="index">
        <div class="saul-u-container">
          <div class="saul-u-content">
            <img style="border-radius: 50%" src="../../assets/img_0454.jpg" width="50" height="50" alt=""/>
            <div class="saul-u-info">
              <p class="saul-u-info-name">{{item.userName}}</p>
              <p class="saul-u-info-account">{{item.userAccount}}</p>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Vue } from 'vue-class-component'
import { Http, LogConsole, WebApi } from '@/common/HttpCommon'

export default class SettingAdminUserList extends Vue {
  userList = []
  errMsg = ''

  mounted () {
    this.queryUserList()
  }

  queryUserList () {
    const params = new FormData()
    Http.requestPost(WebApi.QUERY_USER_LIST, params, success => {
      LogConsole.showConsoleInfo(success)
      if (success.code === 200) {
        this.userList = success.data
      } else {
        this.errMsg = success.msg
      }
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }
}
</script>

<style scoped>
.saul-u-container {
  display: inline-block;
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 16px;
}

.saul-u-container:hover {
  background: #F5F5F5;
  border-radius: 8px;
}

.saul-u-content {
  display: flex;
  border: 1px lightgray solid;
  border-radius: 8px;
  padding: 8px 12px;
}

.saul-u-info {
  margin: auto auto auto 8px;
}
.saul-u-info-name {
  text-align: left;
  color: black;
}
.saul-u-info-account {
  margin-top: 4px;
  font-size: .8125rem;
  text-align: left;
}
</style>
