
import { Vue } from 'vue-class-component'
import { Http, LogConsole, WebApi } from '@/common/HttpCommon'

export default class SettingPrivateCodeBlock extends Vue {
  priCbs = []
  isMenuShow = false
  menuOffsetTop = 0
  menuOffsetLeft = 0
  mounted () {
    this.queryMineDelCb()
  }

  private openMenu (cb: any, event: any): void {
    this.isMenuShow = true
    this.menuOffsetTop = event.pageY
    this.menuOffsetLeft = event.pageX
  }

  /**
   * 查询私有的CB
   * @private
   */
  private queryMineDelCb (): void {
    const params = new FormData()
    Http.requestPost(WebApi.QUERY_MINE_PRIVATE_CB, params, success => {
      LogConsole.showConsoleInfo(success)
      this.priCbs = success.data
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }
}
