
import { Vue } from 'vue-class-component'
import { Http, LogConsole, WebApi } from '@/common/HttpCommon'

export default class UserInfo extends Vue {
  userInfo = Object
  mounted () {
    this.queryUserByUid()
  }

  private queryUserByUid () {
    const params = new FormData()
    Http.requestPost(WebApi.QUERY_USER_BY_UID, params, success => {
      LogConsole.showConsoleInfo(success)
      if (success.code === 200) {
        this.userInfo = success.data[0]
      }
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }
}
