
import { Vue } from 'vue-class-component'
import { Http, LogConsole, WebApi } from '@/common/HttpCommon'
import $ from 'jquery'

export default class LabelManager extends Vue {
  classifyAndLabels = []
  openMenuIndex = -1
  mounted () {
    this.queryClassifyAndLabels()
  }

  private openMenu (index: number) {
    this.openMenuIndex = index
  }

  /**
   * 查询分类和标签列表
   * @private
   */
  private queryClassifyAndLabels (): void {
    const params = new FormData()
    params.append('isContainLabel', '1')
    Http.requestPost(WebApi.QUERY_CLASSIFY_LIST, params, success => {
      LogConsole.showConsoleInfo(success)
      this.classifyAndLabels = success.data
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }
}
