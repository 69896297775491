
import { Options, Vue } from 'vue-class-component'
import { Http, LogConsole, RandomColor, WebApi } from '../common/HttpCommon'
import $ from 'jquery'

export default class SearchCodeBlockResult extends Vue {
  name = 'SearchCodeBlockResult'
  chooseCodeBlockId = ''
  codeBlock = []

  matchTitle = '1'
  matchDescription = '0'
  matchContent = '0'
  deprecatedReason = ''
  deprecatedId = ''

  mounted () {
    const classifyId = this.$route.query.classifyId as string
    const labelId = this.$route.query.labelId as string
    const searchContent = this.$route.query.searchContent as string
    this.matchTitle = this.$route.query.matchTitle as string
    this.matchTitle = this.matchTitle === undefined ? '0' : this.matchTitle
    this.matchDescription = this.$route.query.matchDescription as string
    this.matchDescription = this.matchDescription === undefined ? '0' : this.matchDescription
    this.matchContent = this.$route.query.matchContent as string
    this.matchContent = this.matchContent === undefined ? '0' : this.matchContent
    this.queryCodeBlockList(classifyId, labelId, searchContent)
    this.openOrHideDeprecatedCodeBlock()
  }

  /**
   * 搜索代码
   * @private
   */
  private queryCodeBlockList (classifyId: string, labelId: string, searchContent: string): void {
    if (classifyId.trim() === '' && labelId.trim() === '' && searchContent.trim() === '') {
      (this as any).$parent.$parent.showRemindDialog('至少需要一个条件')
    } else {
      const params = new FormData()
      params.append('classId', classifyId)
      params.append('labelId', labelId)
      params.append('kw', searchContent)
      params.append('matchTitle', this.matchTitle)
      params.append('matchDescription', this.matchDescription)
      params.append('matchContent', this.matchContent)
      Http.requestPost(WebApi.QUERY_SEARCH_CODE_BLOCK_LIST, params, success => {
        LogConsole.showConsoleInfo(success)
        this.codeBlock = success.data
      }, error => {
        LogConsole.showConsoleError(error)
      })
    }
  }

  private getRandomColorClass (): { color: string; display: string; 'border-color': string } {
    const colorS = RandomColor.getRandomColor()
    return {
      color: colorS,
      'border-color': colorS,
      display: 'inline-block'
    }
  }

  private chooseTitleSetId (id: any): void {
    this.chooseCodeBlockId = id
  }

  /**
   * 复制代码
   * */
  private copyCodeBlock (content: string): void {
    const input = document.createElement('textarea')
    input.value = content
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
  }

  /**
   * 打开或关闭编辑列表
   * */
  private openOrHideEditContent (ids: string, event: any): void {
    // eslint-disable-next-line no-eval
    const $cbr = $(eval(ids))
    let width = $cbr.width()
    if (width === undefined) {
      width = 0
    }
    $cbr.css('left', (event.target.offsetLeft - width))
    if ($cbr.is(':hidden')) {
      $cbr.show(300)
    } else {
      $cbr.hide(300)
    }
  }

  /**
   * 打开或关闭标记过时的弹窗
   * */
  private openOrHideDeprecatedCodeBlock (): void {
    const $this = $('#cbrDeprecatedContent')
    if ($this.is(':hidden')) {
      $this.css('visibility', 'visible')
      $this.show(300)
    } else {
      $this.hide(300)
    }
  }

  /**
   * 编辑代码块
   * @private
   */
  private editCodeBlock (block: any, ids: string): void {
    // eslint-disable-next-line no-eval
    $(eval(ids)).hide(300)
    this.$router.push({
      name: 'AddCodeBlock',
      params: {
        codeBlockItem: JSON.stringify(block)
      }
    })
  }

  /**
   * 标记过时
   * @param block
   * @param ids
   * @private
   */
  private deprecatedCodeBlock (block: any, ids: string): void {
    // eslint-disable-next-line no-eval
    $(eval(ids)).hide(300)
    this.deprecatedId = block.id
    this.openOrHideDeprecatedCodeBlock()
  }

  /**
   * 请求接口 标记过时
   * @param block
   * @param ids
   * @private
   */
  private deprecatedCodeBlockRequest (): void {
    if (this.deprecatedReason.trim() === '') {
      (this as any).$parent.$parent.showRemindDialog('过时原因不能为空！')
    } else if (this.deprecatedId === '') {
      (this as any).$parent.$parent.showRemindDialog('需要选择标记过时的CodeBlock！')
    } else {
      const params = new FormData()
      params.append('id', this.deprecatedId)
      params.append('deprecated', '1')
      params.append('deprecatedReason', this.deprecatedReason)
      Http.requestPost(WebApi.DEPRECATED_CODE_BLOCK, params, success => {
        LogConsole.showConsoleInfo(success)
        if (success.code === 200) {
          window.location.reload()
        } else {
          this.showDialog(success.msg)
        }
      }, error => {
        LogConsole.showConsoleError(error)
      })
    }
  }

  /**
   * 删除代码
   * @param block
   * @param ids
   * @private
   */
  private deleteCodeBlock (block: any, ids: string): void {
    // eslint-disable-next-line no-eval
    $(eval(ids)).hide(300)
    let idelete = '1'
    if (block.ifDelete === 1) {
      idelete = '0'
    } else {
      idelete = '1'
    }
    LogConsole.showConsoleInfo(idelete)
    const params = new FormData()
    params.append('id', block.id)
    params.append('delete', idelete)
    Http.requestPost(WebApi.DELETE_CODE_BLOCK, params, success => {
      LogConsole.showConsoleInfo(success)
      if (success.code === 200) {
        window.location.reload()
      } else {
        this.showDialog(success.msg)
      }
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }

  /**
   * 私有或公开
   * @param block
   * @param ida
   * @private
   */
  private privateOrPublicCodeBlock (block: any, ida: string): void {
    // eslint-disable-next-line no-eval
    $(eval(ida)).hide(300)
    let pop = '1'
    if (block.visibility === 1) {
      pop = '0'
    } else {
      pop = '1'
    }
    LogConsole.showConsoleInfo(pop)
    const params = new FormData()
    params.append('id', block.id)
    params.append('visibility', pop)
    Http.requestPost(WebApi.VISIBILITY_CODE_BLOCK, params, success => {
      LogConsole.showConsoleInfo(success)
      if (success.code === 200) {
        window.location.reload()
      } else {
        this.showDialog(success.msg)
      }
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }

  showDialog (str: string): void {
    (this as any).$parent.$parent.showRemindDialog(str)
  }
}
