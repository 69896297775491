
import { Vue } from 'vue-class-component'
import { Http, LogConsole, RandomColor, WebApi } from '../common/HttpCommon'

export default class LabelList extends Vue {
  chooseCodeBlockId = ''
  classifyAndLabels = []

  private getRandomColorClass (): { color: string; display: string; 'border-color': string } {
    const colorS = RandomColor.getRandomColor()
    return {
      color: colorS,
      'border-color': colorS,
      display: 'inline-block'
    }
  }

  private chooseTitleSetId (id: any): void {
    this.chooseCodeBlockId = id
  }

  mounted (): void {
    this.queryClassifyAndLabels()
  }

  /**
   * 去搜索页面
   */
  private goSearchCodeBlockResult (classId: string, labelId: string): void {
    this.$router.push({
      path: '/searchCodeBlockResult',
      query: {
        classifyId: classId,
        labelId: labelId,
        searchContent: ''
      }
    })
  }

  /**
   * 查询分类和标签列表
   * @private
   */
  private queryClassifyAndLabels (): void {
    const params = new FormData()
    params.append('isContainLabel', '1')
    Http.requestPost(WebApi.QUERY_CLASSIFY_LIST, params, success => {
      LogConsole.showConsoleInfo(success)
      this.classifyAndLabels = success.data
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }
}
