
import { Vue } from 'vue-class-component'
import { Http, LogConsole, WebApi } from '@/common/HttpCommon'

export default class SearchMatchSetting extends Vue {
  userInfo = Object
  mounted () {
    this.queryUserByUid()
  }

  private queryUserByUid () {
    const params = new FormData()
    Http.requestPost(WebApi.QUERY_USER_BY_UID, params, success => {
      LogConsole.showConsoleInfo(success)
      if (success.code === 200) {
        this.userInfo = success.data[0]
      } else {
        (this as any).$parent.$parent.$parent.$parent.showRemindDialog(success.msg)
      }
    }, error => {
      (this as any).$parent.$parent.$parent.$parent.showRemindDialog(error.message)
    })
  }

  /**
   * 修改匹配设置
   * @private
   */
  private modifyMatchSetting (type: number, ss: number) {
    const t = type as any
    const s = ss as any
    const params = new FormData()
    params.append('type', t)
    params.append('ss', s)
    Http.requestPost(WebApi.MODIFY_MOD_MATCH_SETTING, params, success => {
      (this as any).$parent.$parent.$parent.$parent.showRemindDialog(success.msg)
      LogConsole.showConsoleInfo(success)
    }, error => {
      (this as any).$parent.$parent.$parent.$parent.showRemindDialog(error.message)
    })
  }
}
