
import Loading from '@/components/Loading.vue'
import { Options, Vue } from 'vue-class-component'
import $ from 'jquery'
import { LogConsole, Http, WebApi } from '@/common/HttpCommon'

@Options({
  components: { Loading }
})
export default class ChooseLabelDialog extends Vue {
  errorMsg = ''
  labels = []
  isLoading = false

  clickBlank (): void {
    // LogConsole.showConsoleInfo('clickBlank')
  }

  mounted (): void {
    const $this = $('#choose-label-container')
    $this.animate({ opacity: 0 }, 500)
    $this.hide()
  }

  closeClick (): void {
    const $this = $('#choose-label-container')
    $this.animate({ opacity: 0 }, 500)
    const interval = setInterval(() => {
      $this.hide()
      this.labels = []
      clearInterval(interval)
    }, 500)
  }

  openDialog (classId: any): void {
    this.queryLabelListByClassId(classId)
    this.errorMsg = ''
    this.isLoading = false
    const $this = $('#choose-label-container')
    $this.show()
    $this.animate({ opacity: 1 }, 500)
  }

  /**
   * 选中或取消选中label
   * */
  private chooseOrUnChooseLabel (item: any): void {
    item.isChoose = !item.isChoose
  }

  /**
   * 选择完成
   * */
  private completeChooseDone (): void {
    let labelIdStr = ''
    let labelNameStr = ''
    this.labels.forEach((val, idx, array) => {
      const item = val as any
      if (item.isChoose) {
        labelIdStr += item.id + ','
        labelNameStr += item.labelName + ','
      }
    })
    labelIdStr = labelIdStr.substring(0, labelIdStr.lastIndexOf(','))
    labelNameStr = labelNameStr.substring(0, labelNameStr.lastIndexOf(','))
    LogConsole.showConsoleInfo(labelIdStr + '----' + labelNameStr)
    if (labelIdStr === '') {
      this.errorMsg = 'No label selected'
    } else {
      // eslint-disable-next-line no-unused-expressions
      (this as any).$parent?.childMethodChooseLabelId(labelIdStr, labelNameStr)
      this.closeClick()
    }
  }

  /**
   * 查询标签列表
   * @private
   */
  private queryLabelListByClassId (classId: any) {
    const params = new FormData()
    params.append('classId', classId)
    Http.requestPost(WebApi.QUERY_LABEL_BY_CLASS_ID, params, success => {
      LogConsole.showConsoleInfo(success)
      success.data.forEach((item: any) => {
        item.isChoose = false
      })
      this.labels = success.data
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }
}

